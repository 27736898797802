import React from 'react'
import Login_Form from '../components/login_form'

const Login = () => {
  return (
    <div>
      <Login_Form/>
    </div>
  )
}

export default Login