import React from 'react'
import Signup_Form from '../components/signup_form'

const Signup = () => {
  return (
    <div>
      <Signup_Form/>
    </div>
  )
}

export default Signup